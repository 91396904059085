import React, {FC} from 'react';

import './Sobre.css';

export const Sobre: FC = () => {
    return (
        <div className="d-flex justify-content-center align-items-center sobre vh-100">
            <div className="overlay"></div>
            <div className="col-md-10 text text-center">
                <div className="my-5">
                    <h2 className="my-4 title">Sobre mim</h2>
                    <p className="mb-4 px-5">Possuo conhecimentos e habilidades para a realização de tratamentos de
                        estética
                        corporal, facial, capilar, para a manipulação de produtos cosméticos com fins terapêuticos,
                        bem como para a utilização de equipamentos de eletroterapias para recursos estéticos, visagismo
                        e
                        produção da imagem.</p>
                </div>
                <div className="my-5">
                    <h2 className="my-4 title">Formação</h2>
                    <p className="mb-4 px-5">
                        <table className="w-100">
                            <tr>
                                <td className="text-left">Universidade Feevale</td>
                            </tr>
                            <tr>
                                <td className="text-left w-75">
                                    Curso Superior de Tecnologia em Estética e Cosmética
                                </td>
                                <td className="text-right w-25 align-text-top">
                                    2017 - cursando
                                </td>
                            </tr>
                        </table>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Sobre;
