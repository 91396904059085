import React, {Suspense} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Loader from "react-loader-spinner";

import './App.css';
import Aside from "./components/Aside/Aside";
import {PAGE} from "./constants";

import Home from "./pages/Home/Home";
import Sobre from "./pages/Sobre/Sobre";
import Contato from "./pages/Contato/Contato";
import NotFound from "./pages/NotFound/NotFound";

function App() {
    return (
        <div className="container-fluid">
            <div className="row d-block">
                <Aside/>
                <div className="main">
                    <Router basename={process.env.PUBLIC_URL}>
                        <Suspense fallback={
                            <Loader
                                type="Puff"
                                color="#000000"
                                height={100}
                                width={100}
                                timeout={3000}
                            />
                        }>
                            <Switch>
                                <Route exact path={PAGE.HOME()} component={Home}/>
                                <Route exact path={PAGE.DICAS()} component={Home}/>
                                <Route exact path={PAGE.SOBRE()} component={Sobre}/>
                                <Route exact path={PAGE.CONTATO()} component={Contato}/>
                                <Route path="*" component={NotFound}/>
                            </Switch>
                        </Suspense>
                    </Router>
                </div>
            </div>
        </div>
    );
}

export default App;
