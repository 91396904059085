import React, {FC, useEffect, useState} from 'react';
import {FaFacebook, FaInstagram, FaLinkedin} from 'react-icons/fa';
import {createBrowserHistory} from "history";

import logo from '../../assets/logo.png'
import './Aside.css';

export const Aside: FC = () => {
    const history = createBrowserHistory();
    const [path, setPath] = useState('/');

    const toogleMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const isActive = e.currentTarget.className.search(/active/g);
        if (isActive === -1) {
            e.currentTarget.classList.add('active');
            document.querySelector('aside')?.classList.add('active');
        } else {
            e.currentTarget.classList.remove('active');
            document.querySelector('aside')?.classList.remove('active');
        }
    };

    useEffect(() => {
        setPath(history.location.pathname);
    }, [history]);

    return (
        <>
            <a href="#" className="nav-toggle" onClick={toogleMenu}>
                <i></i>
            </a>
            <aside className="aside text-center">
                <img src={logo} alt="logo" className="logo" />
                <nav role="navigation" className="menu">
                    <ul>
                        <li className={path === '/' ? 'active' : ''}><a href="/">Home</a></li>
                        {/*<li className={path.search(/dicas/g) >= 0 ? 'active' : ''}><a href="/dicas">Dicas</a></li>*/}
                        <li className={path === '/sobre' ? 'active' : ''}><a href="/sobre">Sobre mim</a></li>
                        <li className={path === '/contato' ? 'active' : ''}><a href="/contato">Contato</a></li>
                    </ul>
                </nav>
                <div className="footer">
                    <ul>
                        <li><a rel="noreferrer" target="_blank"
                               href="https://www.facebook.com/maira.lima.775"><FaFacebook/></a></li>
                        <li><a rel="noreferrer" target="_blank"
                               href="https://www.instagram.com/maira_lima_"><FaInstagram/></a></li>
                        <li><a rel="noreferrer" target="_blank"
                               href="https://www.linkedin.com/in/maira-de-lima-1a1553187"><FaLinkedin/></a></li>
                    </ul>
                </div>
            </aside>
        </>
    );
};

export default Aside;
