import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';

import './NotFound.css';

export const NotFound: FC = () => {
    const history = useHistory();

    const back = () => {
        history.goBack();
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="col-md-8 mt-5 text text-center">
                <div className="desc">
                    <h2 className="subheading">404</h2>
                    <h1 className="mb-4 title">Página não encontrada</h1>
                    <p><a href="#" onClick={back} className="btn-custom">Voltar</a></p>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
