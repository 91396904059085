import React, {FC} from 'react';

import './Contato.css';

export const Contato: FC = () => {
    return (
        <div className="d-flex justify-content-center align-items-center vh-100">
            <div className="overlay"></div>
            <div className="container">
                <div className="row mb-5 text text-center">
                    <div className="col-md-12 mb-4">
                        <h2 className="h4 font-weight-bold">Informações de contato</h2>
                    </div>
                    <div className="col-md-12">
                        <p><span>Telefone:</span> <a href="tel://1234567920">+55 51 998539527</a></p>
                    </div>
                    <div className="col-md-12">
                        <p><span>Email:</span> <a href="mailto:mairajanaina@hotmail.com">mairajanaina@hotmail.com</a></p>
                    </div>
                    <div className="col-md-12">
                        <p><span>Website</span> <a href="http://mairalima.com.br">mairalima.com.br</a></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contato;
