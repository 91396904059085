import React, {FC} from 'react';
import profileImg from '../../assets/profile.jpeg';

import './Home.css';

export const Home: FC = () => {
    return (
        <div className="d-flex justify-content-center align-items-center">
            <div className="col-md-8 mt-5 text text-center">
                <img className="profile-img my-4" alt="Maira Lima" src={profileImg}/>
                <div className="desc">
                    <h2 className="subheading">Olá, sou</h2>
                    <h1 className="mb-4 title">Maira Lima</h1>
                    <p className="mb-4 px-5 text">Atualmente sou estudante do Curso Superior de Tecnologia em Estética e
                        Cosmética da Universidade Feevale, mas minha paixão por esta área não é de hoje! Desde criança
                        sempre senti envolvimento pela estética e cosmetologia, e desde 2017 que venho me dedicando para
                        aprender cada vez mais e também poder compartilhar meu conhecimento com dicas de cuidados com a
                        pele.</p>
                    <p><a href="/sobre" className="btn-custom">Sobre mim</a></p>
                </div>
            </div>
        </div>
    );
};

export default Home;
